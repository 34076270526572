@import "./../../styles/colors";

.header {
  height: clamp(15rem, 70vw, 500px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-position: center;
  background-size: cover;
  max-width: 1020px;
  margin: auto;
  width: 100%;
  margin-bottom: 20px;

  @media (width < 770px) {
    // min-height: 70vh;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    position: relative;
    height: 100%;
  }

  .headerTitle {
    font-weight: 700;
    font-size: 20px;
  }

  .headerContainerImage {
    width: 100%;
    height: 100%;
    position: relative;

    @media (width < 770px) {
    }
    .image {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .headerContainerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px;
    @media (width < 770px) {
      padding-top: 20px;
    }
  }
  h1 {
    font-weight: 900;
    letter-spacing: 0.1rem;
  }

  .eventDetail {
    font-size: 1.5rem;
    width: 100%;
    max-width: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .arrowDown {
    color: white;
    padding: 1rem;
    position: relative;
    border-radius: 100%;
    display: grid;
    margin-bottom: 1rem;

    &:hover {
      color: inherit;
    }
  }

  .discountLabel {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #f00, $alpha: 0.4);
    text-align: center;
    width: 100%;
  }
}

.buttonsContainer {
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin: 0;
  margin: 20px 0;
}

.event-tabs {
  .event-tab {
    padding: 0 15px;
    &.Mui-selected {
      color: $red;
    }
  }
}
.event-tab-indicator {
  background-color: $red;
}
