@use "../../styles/colors";

.register-layout-container {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 10px 0;
  color: colors.$white;
  background-color: colors.$gray;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .register-layout-logo {
    position: absolute;
    width: 80px;
    height: 80px;
    // margin: 1rem;
    top: 20px;
    left: 20px;
    border: none;
    border-radius: 8px;
    background-image: url("../../../public/images/logos/01.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .register-logos-container {
    justify-content: center;
    .register-image {
      width: 100%;
    }
  }
}
