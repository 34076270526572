@use "../../styles/colors";

.event-card-container {
  display: flex;
  overflow: hidden;
  margin: 0;
  width: 100%;
  //min-height: 130px;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  text-decoration: none;

  .event-card-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 819/1024;
  }

  .event-card-inner-container {
    display: flex;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
    // flex-wrap: wrap;
    // flex-grow: 1;

    .event-card-detail-container {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;

      .event-card-name {
        font-size: 17px;
        line-height: 16px;
        font-family: "Alegreya Sans";
        font-weight: 800;
        letter-spacing: 0.05em;
        text-align: left;
        text-transform: uppercase;
        text-decoration: none;
      }

      .event-card-title {
        font-size: 17px;
        line-height: 16px;
        font-family: "Alegreya Sans";
        font-weight: 700;
        letter-spacing: 0.05em;
        text-align: left;
        text-transform: capitalize;
        text-decoration: none;
      }

      .event-card-data {
        font-size: 16px;
        line-height: 24px;
        font-family: "Alegreya Sans";
        letter-spacing: 0.05em;
        text-align: left;
        text-transform: capitalize;
        text-decoration: none;
      }
    }
  }
}

a {
  width: 100%;
  text-decoration: none;
}

@media (max-width: 768px) {
  .event-card-container {
    height: 100%;

    .event-card-inner-container {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0;

      .event-card-title {
        display: none;
      }
    }
  }
}
