@use "../../../styles/colors";

.home-event-card-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  background-color: colors.$black;
  color: colors.$white;

  .home-event-card-inner-container {
    position: relative;
    // width: 300px;
    // height: 200px;
    padding: 10px;
    .home-event-card-image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      aspect-ratio: 819/1024;
    }
    .home-event-card-details {
      display: flex;
      flex-direction: column;

      .home-event-card-title {
        font-size: clamp(14px, 2vw, 16px);
        font-weight: 700;
        text-transform: uppercase;
      }
      .home-event-card-subtitle {
        font-size: clamp(12px, 2vw, 14px);
      }
    }
  }
}
