@use "../../styles/colors";

.home-container {
  display: flex;
  width: 100%;
  padding: 20px 10px 90px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  // min-height: calc(100vh - 60px);
  max-width: 1200px;

  .home-rankings {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    .home-ranking-container {
      display: flex;
      width: 100%;
      max-width: 500px;
      flex-direction: column;
      gap: 15px;
    }
  }
}
.home-title {
  font-size: 16px;
  color: colors.$red;
  align-self: flex-start;
  font-weight: 700;
  text-transform: uppercase;
}
