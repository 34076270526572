@use "../../styles/colors";

.events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 10px 70px;
  gap: 20px;

  .events-top {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    max-width: 1200px;
    height: auto;

    .events-back-button {
      border: 0;
      width: auto;
      padding: 0;
      color: colors.$black;
      background-color: transparent;
      font-weight: 500;
      align-self: flex-start;
      box-shadow: none;
      text-transform: none;
      text-decoration: none;
    }

    .events-filter-icon {
      color: colors.$yellow;
      font-size: 30px;
    }
  }

  .events-select-field {
    height: 40px;
  }

  .events-search-bar {
    height: 40px;
  }
  .events-count {
    align-self: flex-start;
  }

  .events-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: auto;
    gap: 20px;
    padding: 10px;
  }

  .events-date-picker {
    padding: 0;
    background-color: colors.$white;
    height: 40px;
    min-width: 200px;
    & > * {
      height: 40px;
    }
  }
}

@media (max-width: 768px) {
  .events-container {
    height: 100%;

    .events-top {
      display: flex;
    }

    .events-select-field {
      display: none;
    }
    .events-count {
      display: none;
    }
  }
}
