@use "../../../styles/colors";

.documents-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: auto;
  // min-height: calc(100vh - 60px);
  padding: 20px 10px 40px;
  // margin-bottom: 70px;

  .documents-back-button {
    width: auto;
    background-color: transparent;
    border: 0;
    color: colors.$black;
    font-weight: 500;
    align-self: flex-start;
    padding: 0;
    box-shadow: none;
    text-transform: none;
    text-decoration: none;
  }

  .documents-grid-container {
    max-width: 500px;
  }

  .documents-btn {
    justify-content: flex-start;
    gap: 10px;
    text-transform: none;
  }
}
