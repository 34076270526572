@import "../../styles/colors";

.club {
  padding: 0;
  padding-bottom: 100px;
  &__first-container {
    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    border-radius: 0 0 15% 15%;
    &__avatar-text-container {
      justify-content: center;
      gap: 10px;
      margin-bottom: 10px;
    }

    &__button {
      color: $white;
      font-size: 12px;
      align-self: flex-start;
    }
    &__image-container {
      width: 100px;
      height: 100px;
      &__avatar {
        width: 100%;
        height: 100%;
      }
    }

    &__text-container {
      padding-inline: 10px;
      &__name {
        color: $white;
        text-align: center;
      }
    }
  }

  &__second-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 20px;
    gap: 20px;
    width: 100%;

    &__text {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $black;
      &__link {
        color: $black;
      }
    }

    &__weekSchedule {
      display: flex;
      flex-direction: column;
      padding-left: 50px;
    }
  }

  &__container-map {
    margin-left: 20px;
    margin-top: 20px;
    &__map {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (width > 769px) {
  .club {
    justify-content: flex-end;
    padding: 10px;
    margin: 20px;
    margin-top: 50px;
    border-radius: 20px;
    width: auto;
    &__first-container {
      background-color: $white;
      margin-top: 10px;

      &__image-container {
        width: 30%;
        height: 30%;
        max-width: 250px;
        max-height: 250px;
        aspect-ratio: 1 / 1;
        position: absolute;
        left: 5%;
        top: 20%;
        &__avatar {
          width: 100%;
          height: 100%;
        }
      }
      &__text-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 10px;
        flex: 1;
        margin-left: 35%;
        text-wrap: pretty;
        &__name {
          color: $black;
          text-align: start;
          font-size: 20px;
          font-weight: 800;
        }
      }

      &__button {
        color: $black;
        position: absolute;
        top: 80px;
        left: 0;
      }
    }

    &__second-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      &__text {
        display: flex;
        gap: 40px;
        &__bold {
          font-weight: 600;
        }
      }
    }
  }
}

@media (width > 1024px) {
  .club__first-container__text-container {
    margin-left: 20%;
  }
}
