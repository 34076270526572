@use "../../../../../styles/colors";

.dialog-title {
  font-weight: bolder;
  color: colors.$red;
  padding-top: 20px;
}

.dialog-text {
  color: #000;
  text-align: center;
}

.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 20px;
  padding-inline: 20px;
}

.button-cancel {
  font-size: 20px;
  font-weight: 800;
  height: 40px;
  background-color: colors.$white;
  color: colors.$red;
  &:hover {
    background-color: colors.$red;
    color: colors.$white;
  }
}

.button-accept {
  font-size: 20px;
  font-weight: 800;
  height: 40px;
  color: colors.$white;
  &:hover {
    background-color: colors.$yellow;
  }
}
