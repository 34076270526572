@use "../../styles/colors";

.terms {
  font-family: "Alegreya Sans";
  text-align: right;
  font-weight: normal;
  &-title {
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    word-spacing: 4px;
    font-weight: bold;
  }

  &-subtitle {
    font-size: 20px;
    line-height: 30px;
    word-spacing: 4px;
    font-weight: 600;
  }

  &-contenido {
  }
}
