@use "../../../styles/colors";

.sport-sheet-container {
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px 10px 90px;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  // margin-bottom: 70px;

  .sport-sheet-back-button {
    border: 0;
    width: auto;
    padding: 0;
    color: colors.$black;
    background-color: transparent;
    font-weight: 500;
    align-self: flex-start;
    box-shadow: none;
    text-transform: none;
    text-decoration: none;
  }

  .sport-sheet-inner-container {
    display: flex;
    width: 100%;
    padding: 30px 20px;
    flex-direction: column;
    gap: 10px;
    max-width: 1200px;
    .sport-sheet-avatar {
      margin: auto;
      width: clamp(120px, 20vw, 232px);
      height: clamp(120px, 20vw, 232px);
    }

    .sport-sheet-profile-table {
      width: 100%;
      td {
        border-color: colors.$gray;
        font-family: "Alegreya Sans";
      }
    }

    .sport-sheet-flag-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      .sport-sheet-flag {
        width: clamp(40px, 10vw, 140px);
        height: clamp(30px, 10vw, 90px);
        object-fit: cover;
      }
    }

    .sport-sheet-divider {
      margin: 20px 0;
      width: 100%;
    }

    .sport-sheet-sport-table-container {
      //   gap: clamp(0, 10vw, 50px);
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .sport-sheet-inner-sport-table {
        display: flex;
        flex-direction: row;
        max-width: 440px;
        margin-right: 50px;
        .sport-sheet-sport-table {
          width: 100%;
          max-width: 440px;
          td {
            border-color: colors.$gray;
            font-family: "Alegreya Sans";
          }
        }
      }
    }
  }

  .sport-sheet-table-title {
    font-weight: 700;
  }
}
