@use "../../styles/colors";

.ranking-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px 15px 90px;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  max-width: 1200px;
  .ranking-header {
    display: flex;
    width: 100%;
    align-items: center;

    .ranking-search {
      width: 50%;
    }

    .ranking-filter-icon {
      display: none;
      color: colors.$yellow;
    }
  }

  .ranking-body {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: colors.$dark-gray;
    flex-direction: column;
    justify-content: flex-start;
    .ranking-table-top {
      display: flex;
      width: 100%;
      height: 65px;
      padding: 10px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .ranking-table-top-title {
        // gap: 10px;
        display: flex;
        color: colors.$white;
        align-items: flex-start;
        flex-direction: column;
        font-family: "Alegreya Sans";
        justify-content: flex-start;
        .ranking-title {
          font-size: clamp(12px, 20vw, 14px);
          font-weight: 700;
        }
        .ranking-date {
          font-size: clamp(10px, 20vw, 12px);
        }
      }

      .ranking-table-top-filters {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        justify-content: space-around;
        .ranking-select-field {
          height: 35px;
        }
        .ranking-date-picker {
          padding: 0;
          background-color: colors.$white;
          height: 35px;
          min-width: 200px;
          & > * {
            height: 35px;
          }
        }
      }
    }

    .ranking-card-table-container {
      width: 100%;
      height: 100%;
      background-color: colors.$white;
      .ranking-card-table-head {
        background-color: colors.$gray;
      }
      .ranking-card-table-row {
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 769px) {
  .ranking-container {
    padding: 30px 10px;
    .ranking-header {
      .ranking-search {
        width: 100%;
      }
      .ranking-filter-icon {
        display: flex;
      }
    }

    .ranking-body {
      .ranking-table-top {
        .ranking-table-top-filters {
          display: none;
        }
      }
    }
  }

  .ranking-card-table-cell {
    padding: 10px 0;
    white-space: wrap;
  }
}
