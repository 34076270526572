@use "../../../styles/colors";

.ranking-card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  background-color: colors.$dark-gray;
  padding: 0;

  .ranking-card-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;

    .ranking-card-header-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      width: 100%;

      .ranking-card-header-titles {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        color: colors.$white;
        text-align: start;

        .ranking-card-name {
          font-weight: 700;
          font-size: 12px;
        }
        .ranking-card-date {
          font-size: 10px;
        }
      }

      .ranking-card-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 5px;

        &:nth-child(1) {
          display: none;
        }
        .ranking-card-select-field {
          width: 100px;
          height: 35px;
          font-size: 12px;
        }
      }
    }
  }

  .ranking-card-table-container {
    background-color: colors.$white;
    width: 100%;

    .ranking-card-table-cell {
      padding: 5px 10px;
      align-items: center;
      //   display: flex;
      //   flex-direction: row;
      //   gap: 5px;
    }

    .ranking-card-flag {
      width: 20px;
      height: 15px;
      object-fit: contain;
      border-radius: 15%;
      margin: auto 0;
      margin-right: 5px;
    }
  }

  .ranking-card-see-more {
    margin: auto 0 0;
    width: 100%;
    align-items: center;

    .ranking-card-see-more-button {
      text-decoration: none;
      text-transform: none;
    }
  }
}
