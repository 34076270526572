@import "./../../../styles/colors";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  background-color: $yellow;
  font-family: "Alegreya Sans";
  outline: none;
  font-weight: 500;
  &:hover {
    background-color: $yellow;
  }
}
