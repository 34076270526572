@import "../../styles/colors";

.calendario-container {
  width: 100%;
  .events-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    background-color: $black;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: $white;
    height: 100%;
    min-height: 350px;
    .event-info {
      display: flex;
      justify-content: space-between;
      width: 90%;
      background-color: rgba(231, 175, 19, 0.7);
      border: 1px solid $yellow;
      border-radius: 25px;
      padding: 0 5px 0 10px;

      .button-show-event {
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        background-color: $yellow;
        font-family: "Alegreya Sans";
        outline: none;
        font-weight: 500;
        border-radius: 20px;
        a {
          color: $white;
        }
      }
    }
  }
}

.selected {
  background-color: $yellow;
  border-radius: 100%;
  color: $white;
  button {
    color: $white;
  }
}
