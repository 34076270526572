@use "../../styles/colors";

.circular-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  .circular-progress {
    color: colors.$yellow;
  }
}
