@use "../../../../styles/colors";

.inscription-card-container {
  width: 100%;
  background-color: colors.$white;
  border-radius: 15px;
  color: colors.$black;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);

  .inscription-card-cover {
    width: 80px;
    height: 80px;
    object-fit: inherit;
    object-position: center;
    border-radius: 15px;
  }
  .inscription-card-details-container {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .inscription-card-title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
    }
    .inscription-card-subtitle {
      font-size: 12px;
    }

    .inscription-card-button {
      text-transform: none;
      font-size: 20px;
      &.approved {
        background-color: colors.$green;
      }
      &.pending {
        background-color: colors.$yellow;
      }
      &.rejected {
        background-color: colors.$red;
      }
      &.reserved {
        background-color: colors.$blue;
      }
    }
  }
}

@media (width > 768px) {
  .inscription-card-container {
    height: 150px;
    .inscription-card-cover {
      width: 120px;
      height: 130px;
    }
    .inscription-card-details-container {
      height: 120px;
    }
  }
}
