@use "../../../styles/colors";

.personalData-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding: 20px 10px 90px;

  .personalData-back-button {
    width: auto;
    background-color: transparent;
    border: 0;
    color: colors.$black;
    font-weight: 500;
    align-self: flex-start;
    padding: 0;
    box-shadow: none;
    text-transform: none;
    text-decoration: none;
  }

  .personalData-avatar-container {
    width: 280px;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .personalData-avatar {
      height: 147px;
      width: 147px;
      border: 10px solid colors.$yellow;
      background-color: colors.$black;
    }

    .personalData-avatar-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      height: 40px;
      color: colors.$white;
      font-weight: 500;
      align-self: flex-end;
      padding: 0;
    }
  }

  .personalData-title {
    color: colors.$black;
    font-weight: 500;
    font-size: 14px;
    align-self: flex-start;
  }
  .personal-data-select-field {
    max-height: 45px;
  }

  .allergies-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media (width > 620px) {
  .personalData-container {
    width: 90%;
  }
}
