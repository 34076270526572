@use "../../styles/colors";

.new-password-container {
  width: min(100%, 400px);
  height: 100vh;
  padding: 30px 0;
  background-color: colors.$gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .new-password-logo {
    width: 100%;
    height: 300px;
    margin: 1rem;
    border: none;
    border-radius: 8px;
    background-image: url("../../../public/images/logos/01.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .new-password-title {
    width: 100%;
    color: colors.$black;
    font-size: 20px;
    font-family: "Alegreya Sans";
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    pointer-events: none;
  }

  .new-password-text-field {
    width: 100%;

    & > * {
      height: 45px;
      border: 1px solid colors.$black;

      & > * svg {
        color: colors.$yellow;
      }

      & > input {
        padding: 0;
      }
    }
  }

  .new-password-checkbox-container {
    margin: 5px 0;
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 5px;
    .new-password-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 20px;
      height: 20px;
      pointer-events: auto;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(3, 3, 3, 0.1);
      border: 1px solid rgba(3, 3, 3, 0.1);
      &.Mui-checked {
        color: colors.$yellow;
        border: 1px solid colors.$yellow;
        background-color: colors.$white;
      }
    }
    .MuiTypography-root {
      color: #232323;
      font-size: 16px;
      font-family: "Alegreya Sans";
    }
  }

  .new-password-button-new-password {
    // width: 50%;
    color: colors.$white;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-transform: none;
    outline: none;
    text-wrap: nowrap;
  }

  .new-password-rule-text {
    display: flex;
    align-items: center;
    color: colors.$black;
    width: 100%;
    font-size: 14px;
    font-family: "Alegreya Sans";
    line-height: 15px;
    text-align: left;
    gap: 5px;
  }

  .new-password-rule-check {
    color: colors.$yellow;
  }
  .new-password-rule-cross {
    color: colors.$red;
  }

  .new-password-error-text {
    color: colors.$red;
    width: 100%;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .new-password-container {
    margin: auto;
    padding: 20px;
  }
}
