@use "../../../styles/colors";

.results-container {
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px 0 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  // padding-bottom: 70px;

  .results-top {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;

    .results-back-button {
      border: 0;
      width: auto;
      padding: 0;
      color: colors.$black;
      background-color: transparent;
      font-weight: 500;
      align-self: flex-start;
      box-shadow: none;
      text-transform: none;
      text-decoration: none;
      font-family: "Alegreya Sans";
    }
  }
  .results-avatar {
    border: 10px solid colors.$yellow;
    width: 125px;
    height: 125px;
    background-color: colors.$black;
  }

  .results-name {
    font-family: "Alegreya Sans";
    font-size: 2.5rem;
  }

  .results-title {
    font-family: "Alegreya Sans";
    font-size: 1rem;
  }

  .results-scores-container {
    display: flex;
    width: 70%;
    height: 150px;
    padding: 10px;
    flex-direction: row;
    gap: 10px;
    max-width: 750px;
    justify-content: space-between;
    align-items: center;

    .results-scores-details-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .results-score-points {
        font-size: 2rem;
      }
    }
  }

  .results-divider {
    border: 1px solid colors.$red;
    color: colors.$red;
  }

  .results-medals-container {
    display: flex;
    width: 70%;
    padding: 20px;
    flex-direction: column;
    gap: 10px;
    max-width: 750px;
    justify-content: space-around;
    align-items: flex-start;

    .results-medals-inner-container {
      display: flex;
      margin: 0 auto;
      width: 90%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .results-medal-container {
        display: flex;
        width: 50px;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .results-medal-img {
          width: 26px;
          height: 26px;
          object-fit: contain;
        }

        .results-score-medal-score {
          font-weight: 700;
        }
      }
    }
  }

  .results-last-scores-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .results-last-scores-top {
      display: flex;
      width: 100%;
      color: colors.$white;
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      background-color: colors.$yellow;
      border-radius: 10px 10px 0 0;
    }

    .results-last-scores-table-container {
      width: 100%;
      height: 100%;
      background-color: colors.$black;
      padding: 10px 5px 40px;
      .results-last-scores-table {
        border: 0;
        border-color: transparent;

        td {
          border-bottom: 1px solid colors.$white;
          font-family: "Alegreya Sans";
          font-size: 1rem;
          color: colors.$white;
        }
      }
    }
  }
}
