@use "../../styles/colors";

.footer-container {
  background-color: colors.$black;
  width: 100%;
  max-width: 100vw;
  padding: 2rem clamp(5rem, 20vw, 10rem);
  z-index: 1000;

  .footer-logo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .footer-logo {
      width: clamp(250px, 20vw, 340px);
      height: clamp(70px, 20vh, 90px);
      object-fit: contain;
    }
  }

  .footer-section {
    display: flex;
    height: auto;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 5px 0;
      }
    }

    .footer-divider {
      background-color: colors.$white;
      margin-right: 20px;
    }

    .footer-link-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 10px;

      .footer-title {
        color: colors.$white;
        font-family: "Alegreya Sans";
        font-weight: 700;
        font-size: clamp(14px, 20vw, 20px);
      }

      .footer-link {
        text-decoration: none;
        color: colors.$white;
        font-family: "Alegreya Sans";
        font-size: clamp(12px, 20vw, 16px);
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .footer-red-logo {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-container {
    display: none;
  }
}
