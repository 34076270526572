@import "./_colors.scss";
@import "./fonts";

html body {
  margin: 0;
  background-color: $gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Alegreya Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.input-text .MuiInputBase-root {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 21px;
  color: $black;
  background-color: $white;
  border-radius: 6px;
  font-family: "Alegreya Sans";
  min-height: 30px;
  outline: none;
}

.input-label {
  font-size: 13px;
  line-height: 13px;
  color: $black;
  font-family: "Alegreya Sans";
  font-weight: 400;
  margin-bottom: 3px;
}
.input-error {
  // margin-bottom: 3px;
  font-size: 11px;
  line-height: 13px;
  color: $red;
  font-family: "Alegreya Sans";
  font-weight: 400;
}

.text-field {
  width: 100%;
  border-radius: 6px;
  & > * {
    border-radius: 6px;
    //border: 1px solid $black;
    height: 45px;
    & > * svg {
      color: $yellow;
    }
  }
  &.updated {
    border: 1px solid $yellow;
    border-radius: 6px;
  }
}

.input-select {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 21px;
  color: $black;
  background-color: $white;
  border-radius: 6px;
  font-family: "Alegreya Sans";
  min-height: 30px;
  outline: none;
}
.form-select-field {
  width: 100%;
  height: 45px;
}

.checkbox-container {
  margin: 0;
  .checkbox {
    color: $red;
  }
}

.carouselBox {
  margin: 10px 0;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
  position: relative;
}

.carouselWrapper {
  display: flex;
  width: fit-content;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  scroll-snap-type: x mandatory;
}

.headerh3 {
  padding: 5px;
  font-size: 1.5rem;
  line-height: 2;
  color: $black;
  border-bottom: 1px solid;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  font-family: "Alegreya Sans";
  font-weight: 700;
  text-align: center;
}

.swiper {
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
}

.swiper-slide {
  width: 30px;
  height: fit-content;
  background-position: center;
  background-size: cover;
}

.swiper-3d .swiper-wrapper {
  padding: 10px;
}

.swiper-3d .swiper-slide,
.swiper-3d .swiper-cube-shadow {
  height: auto;
}

.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom .icon-color-yellow {
  border-radius: 20px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: $yellow;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: $yellow;
}

.check-radio-orange {
  color: $yellow;
  &.Mui-checked {
    color: $yellow;
  }
}
