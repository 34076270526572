@use "../../styles/colors";

.register-container {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  max-width: 800px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .register-title {
    margin: 10px auto 20px;
    font-size: 3rem;
    color: colors.$yellow;
    text-transform: uppercase;
    padding-left: 50px;
    font-weight: 700;
    font-family: "Alegreya Sans";
  }

  .register-subtitle {
    margin: 0 auto;
    font-size: 1rem;
    color: colors.$black;
    text-align: center;
    font-family: "Alegreya Sans";
  }

  .register-text-field {
    width: 100%;
    background-color: colors.$white;

    & > * {
      height: 45px;

      & > * svg {
        color: colors.$yellow;
      }

      & > input {
        padding: 0;
      }
    }
  }

  .register-button {
    font-size: 23px;
    line-height: 30px;
    // width: 50%;
    color: colors.$white;
    font-family: "Alegreya Sans";
    font-weight: 600;
    text-transform: none;
    outline: none;
    text-wrap: nowrap;
  }

  .register-input-error {
    font-size: 1rem;
    line-height: 1rem;
    color: colors.$red;
    font-family: "Alegreya Sans";
    font-weight: 400;
    margin-top: 5px;
  }

  .register-calendar-icon {
    color: colors.$yellow;
    padding-right: 6px;
  }
}
