@use "../../styles/colors";

.login-container {
  width: min(100%, 400px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .login-logo {
    width: 100%;
    height: 300px;
    margin: 1rem;
    border: none;
    border-radius: 8px;
    background-image: url("../../../public/images/logos/01.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .login-title {
    width: 350px;
    font-size: 23px;
    font-family: "Alegreya Sans";
    // font-weight: 600;
    line-height: 30px;
    text-align: center;
    word-spacing: 4px;
  }

  .login-text-field {
    width: 100%;
    background-color: colors.$white;

    & > * {
      height: 45px;
      //border: 1px solid colors.$black;

      & > * svg {
        color: colors.$yellow;
      }

      & > input {
        padding: 0;
      }
    }
  }

  .login-checkbox-container {
    margin: 5px 0;
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 5px;
    .login-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 20px;
      height: 20px;
      pointer-events: auto;
      border-radius: 5px;
      &.Mui-checked {
        color: colors.$yellow;
        border: 1px solid colors.$yellow;
        background-color: colors.$white;
      }
    }
    .MuiTypography-root {
      color: #232323;
      font-size: 16px;
      font-family: "Alegreya Sans";
    }
  }

  .login-error-text {
    color: colors.$red;
    width: 100%;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: center;
  }

  .login-button-login {
    // width: 50%;
    color: colors.$white;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-transform: none;
    outline: none;
    text-wrap: nowrap;
  }

  .login-forget-text {
    color: colors.$red;
    width: 100%;
    font-size: 18px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: center;
    text-decoration: none;
  }

  .login-link-create {
    color: colors.$black;
    font-size: 18px;
    font-family: "Alegreya Sans";
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;
  }
  .login-logos-container {
    margin-top: auto;

    .login-image {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .login-container {
    margin: auto;
    padding: 20px;
  }
}
