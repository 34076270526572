@use "../../../styles/colors";

.direction-title {
  color: colors.$black;
  font-size: 19px;
  font-family: "Alegreya Sans";
  font-weight: 700;
  line-height: 25px;
}

.direction-checkbox-container {
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
  .direction-checkbox {
    // cursor: pointer;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // overflow: hidden;
    // width: 16px;
    // height: 20px;
    pointer-events: auto;
    // background-color: rgba(255, 255, 255, 1);
    // border-radius: 5px;
    // box-shadow: 0 2px 4px rgba(3, 3, 3, 0.1);
    // border: 1px solid rgba(3, 3, 3, 0.1);
    scale: 0.8 1;
    &.Mui-checked {
      color: colors.$red;
      border: 1px solid colors.$red;
      background-color: transparent;
    }
  }
  .MuiTypography-root {
    // color: #232323;
    // font-size: 16px;
    font-family: "Alegreya Sans";
  }
}
