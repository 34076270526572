@use "../../styles/colors";

.forget-container {
  width: min(100%, 400px);
  height: 100vh;
  background-color: colors.$gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .forget-logo {
    width: 100%;
    height: 300px;
    margin: 1rem;
    border: none;
    border-radius: 8px;
    background-image: url("../../../public/images/logos/01.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .forget-back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 0;
    .forget-back-icon {
      color: colors.$yellow;
      width: 100%;
      height: 100%;
    }
  }

  .forget-text {
    width: 100%;
    color: colors.$black;
    font-size: 20px;
    font-family: "Alegreya Sans";
    line-height: 30px;
    text-align: center;
    pointer-events: none;

    &.forget-title {
      font-weight: 600;
    }

    &.forget-subtitle {
      font-weight: 500;
    }
  }

  .forget-text-field {
    width: 100%;

    & > * {
      height: 45px;

      & > * svg {
        color: colors.$yellow;
      }
      & > input {
        padding: 0;
      }
    }
  }

  .forget-error {
    color: colors.$red;
    width: 100%;
    font-size: 16px;
    font-family: "Alegreya Sans";
    line-height: 21px;
    text-align: center;
  }

  .forget-button {
    color: colors.$white;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-transform: none;
    outline: none;
    text-wrap: nowrap;
  }
  .forget-logos-container {
    margin-top: auto;
    .forget-image {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .forget-container {
    margin: auto;
    padding: 20px;

    .forget-back-button {
      display: block;
    }
  }
}
