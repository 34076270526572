.container {
  padding: 20px;
  margin-bottom: 60px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

@media (width > 1024px) {
  .form-container {
    width: 60%;
    margin: 0 auto;
  }
}

.rfejyda-inscription-minors {
  display: none;
}
