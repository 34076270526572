@use "../../styles/colors";

.verify-container {
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: hidden;

  .verify-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 80px 0 0;

    .verify-title {
      font-family: "Alegreya Sans";
      font-size: 1rem;
      font-weight: 700;
      color: colors.$black;
      text-align: center;
      letter-spacing: 2px;
    }
    .verify-subtitle {
      font-family: "Alegreya Sans";
      font-size: 1rem;
      color: colors.$black;
      text-align: center;
    }
  }

  .verify-button {
    color: colors.$white;
    font-size: 23px;
    font-family: "Alegreya Sans";
    font-weight: 600;
    line-height: 30px;
    text-transform: none;
    outline: none;
    text-wrap: nowrap;
  }
}

@media (width > 500px) {
  .set-password-container .set-password-form-container {
    width: 70%;
  }
}

@media (width > 700px) {
  .set-password-container .set-password-form-container {
    width: 50%;
  }
}

@media (width > 900px) {
  .set-password-container .set-password-form-container {
    width: 40%;
  }
}
