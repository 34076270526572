@use "../../styles/colors";

.footer-mobile-container {
  width: 100%;
  background-color: colors.$black;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 5px;
  border-top: 2px solid colors.$yellow;
  z-index: 1000;

  .footer-mobile-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Alegreya Sans";
    font-size: 15px;
    color: colors.$white;
    text-decoration: none;
    .footer-mobile-icon {
      font-size: 30px;
    }

    &.active {
      color: colors.$yellow;
    }
  }

  .login-ball {
    &::after {
      position: absolute;
      background-color: colors.$green;
      top: 5px;
      right: 4%;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      content: "";
    }
  }
}
