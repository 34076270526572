@import "../../../styles/colors";

.container-results {
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 50px;
  max-width: 1020px;

  &__head {
    border-bottom: 2px solid $red;
  }

  &__wrap {
    white-space: nowrap;
  }
}
