@use "../../../styles/colors";

.inscriptions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: auto;
  // min-height: calc(100vh - 60px);
  padding: 20px 10px 40px;
  margin-bottom: 70px;

  .inscriptions-top {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    max-width: 1200px;
    height: auto;

    .inscriptions-back-button {
      border: 0;
      width: auto;
      padding: 0;
      color: colors.$black;
      background-color: transparent;
      font-weight: 500;
      align-self: flex-start;
      box-shadow: none;
      text-transform: none;
      text-decoration: none;
    }

    .inscriptions-filter-icon {
      color: colors.$red;
      font-size: 30px;
    }
  }

  .inscriptions-search-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    width: 100%;
    max-width: 900px;
    height: auto;

    .inscriptions-search-icon {
      color: colors.$yellow;
      font-size: 30px;
    }

    .inscriptions-select-field {
      height: 40px;
    }

    .inscriptions-search-bar {
      height: 40px;
    }
  }
  .inscriptions-inner-container {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .inscriptions-container {
    height: 100%;

    .inscriptions-top {
      display: flex;
    }

    .inscriptions-select-field {
      display: none;
    }
    .inscriptions-count {
      display: none;
    }
  }
}
