@use "../../../styles/colors";

.appBar-container {
  box-shadow: none;
  background-color: colors.$black;
  width: 100%;
  height: 80px;
  padding: 10px 0;

  .appBar-toolbar {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0;
    align-items: center;

    .appBar-logo {
      margin: 0;
      width: 70px;
      height: 70px;
      border-radius: 8px;
      background-image: url("../../../../public/images/logos/Logo.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .appBar-link-container {
      margin: 0 auto;
      .appBar-link-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        list-style: none;
        margin: 0;
        padding: 0;

        .appBar-link {
          color: colors.$white;
          font-family: "Alegreya Sans";
          text-decoration: none;
        }
        .login-ball-pc {
          &::after {
            position: absolute;
            background-color: colors.$green;
            top: 12px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            content: "";
          }
        }
      }
    }

    .appBar-calendar-container {
      display: none;
      width: auto;
      align-items: center;
      margin: 0;
      padding: 0;

      .appBar-calendar-icon {
        width: 30px;
        height: 30px;
        color: colors.$white;
      }
    }
  }
}

.active {
  color: colors.$yellow !important;
  border-bottom: 1px solid colors.$yellow;
  padding-bottom: 1px;

  & > * {
    color: colors.$yellow !important;
  }
}

@media (max-width: 769px) {
  .MuiToolbar-root {
    height: 70px;
  }
  .appBar-container {
    height: 70px;
    background-color: colors.$black;

    .appBar-toolbar {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      .appBar-logo {
        width: 45px;
        height: 45px;
      }

      .appBar-link-container {
        display: none;
      }
      .appBar-calendar-container {
        display: flex;
      }
    }
  }

  .active {
    color: colors.$yellow !important;
    border-bottom: 0;
    padding-bottom: 0;

    & > * {
      color: colors.$yellow !important;
    }
  }
}
