@import "../../../styles/colors";

.event-info-accordion {
  //background-color: colors.$gray;
  margin: 0 !important;

  &__summary {
    color: black;
  }
}
