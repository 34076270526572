@use "../../styles/colors";

.register-user-container {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .register-user-stepper-container {
    // width: 60%;
    margin: 25px auto 20px;
    padding-left: 80px;

    .register-user-stepper-connector {
      width: 5vw;
      height: 3px;
      background-color: colors.$black;
    }

    .MuiStep-root {
      margin: 0;
      padding: 0;

      .register-user-step-label {
        cursor: pointer;
        color: colors.$black;
        & > * {
          margin: 0;
          padding: 0;
        }

        &.completed {
          color: colors.$green;
        }
        &.error {
          color: colors.$red;
        }
      }
    }
  }

  .form-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .register-form-title {
      font-size: 16px;
      font-weight: 700;
      color: colors.$black;
      // margin-bottom: 20px;
    }

    .register-form-data-container {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .register-text-field-no-icon {
        background-color: colors.$white;
      }

      .register-text-field {
        width: 100%;
        background-color: colors.$white;

        & > * {
          height: 40px;

          & > * svg {
            color: colors.$yellow;
          }
          & > input {
            padding: 0;
          }
        }
      }

      .register-select-field {
        height: 40px;
      }

      .register-input-error {
        color: colors.$red;
        width: 100%;
        font-size: 16px;
        font-family: "Alegreya Sans";
        line-height: 21px;
        text-align: left;
      }
      .register-input-helper-text {
        margin-top: 5px;
        color: colors.$black;
        width: 100%;
        font-size: 16px;
        font-family: "Alegreya Sans";
        line-height: 21px;
        text-align: left;
      }

      .register-checkboxes-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 50px 0;

        .register-checkbox-container {
          margin: 5px 0;
          display: flex;
          align-items: flex-start;
          gap: 5px;
          .register-checkbox {
            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 16px;
            height: 20px;
            pointer-events: auto;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(3, 3, 3, 0.1);
            border: 1px solid rgba(3, 3, 3, 0.1);
            &.Mui-checked {
              color: colors.$yellow;
              border: 1px solid colors.$yellow;
              background-color: colors.$white;
            }
          }
          .MuiTypography-root {
            color: #232323;
            font-size: 16px;
            font-family: "Alegreya Sans";
            // line-height: 21px;
          }

          a {
            text-transform: none;
            text-decoration: underline;
            color: colors.$black;
          }
        }
      }
    }

    .register-button {
      // width: 50%;
      color: colors.$white;
      font-size: 23px;
      font-family: "Alegreya Sans";
      font-weight: 600;
      line-height: 30px;
      text-transform: none;
      outline: none;
      text-wrap: nowrap;
    }
  }
}

.form-input-label {
  color: colors.$black;
  font-size: 13px;
  font-family: "Alegreya Sans";
  font-weight: 400;
  line-height: 13px;
  margin-bottom: 3px;
}

.form-input-title {
  color: colors.$black;
  font-size: 18px;
  font-family: "Alegreya Sans";
  font-weight: 600;
  line-height: 13px;
  margin-bottom: 15px;
}
.form-input-text {
  color: colors.$black;
  font-size: 13px;
  font-family: "Alegreya Sans";
  font-weight: 600;
  line-height: 13px;
  margin-bottom: 3px;
}

.form-switch {
  width: 50px;
  height: 25px;
  padding: 0;
  display: flex;
  &:active {
    & .MuiSwitch-thumb {
      width: 27px;
    }
    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(21px);
    }
  }
  & .MuiSwitch-switchBase {
    padding: 2px;
    &.Mui-checked {
      transform: translateX(24px);
      color: #fff;
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: colors.$yellow;
      }
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 21px;
    height: 21px;
    border-radius: 18px;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  & .MuiSwitch-track {
    border-radius: 16px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
  }
}

.check-radio-red {
  color: colors.$red;
  &.Mui-checked {
    color: colors.$red;
  }
}

.rfejyda-register-hidden {
  display: none;
}

// @media (width > 768px) {
//   .register-user-container {
//     .form-container {
//       .register-form-data-container {
//         width: 60%;
//       }
//     }
//   }
// }
