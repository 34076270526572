@use "../../styles/colors";

.set-password-container {
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: hidden;

  .set-password-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 80px 0 0;

    .set-password-title {
      font-family: "Alegreya Sans";
      font-size: 1rem;
      font-weight: 700;
      color: colors.$black;
      text-align: center;
      letter-spacing: 2px;
    }
    .set-password-subtitle {
      font-family: "Alegreya Sans";
      font-size: 1rem;
      color: colors.$black;
      text-align: center;
      //   letter-spacing: 2px;
    }
  }

  .set-password-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    .set-password-text-field {
      width: 100%;
      background-color: colors.$white;

      & > * {
        height: 45px;

        & > * svg {
          color: colors.$yellow;
        }

        & > input {
          padding: 0;
        }
      }
    }

    .set-password-input-error {
      color: colors.$red;
      font-size: 1rem;
      font-family: "Alegreya Sans";
      font-weight: 400;
      line-height: 1rem;
      margin-top: 5px;
    }

    .set-password-checkboxes-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 50px 0;

      .set-password-checkbox-container {
        margin: 5px 0;
        display: flex;
        align-items: flex-start;
        gap: 5px;
        .set-password-checkbox {
          // cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          width: 16px;
          height: 20px;
          pointer-events: auto;
          background-color: rgba(255, 255, 255, 1);
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(3, 3, 3, 0.1);
          border: 1px solid rgba(3, 3, 3, 0.1);
          &.Mui-checked {
            color: colors.$yellow;
            border: 1px solid colors.$yellow;
            background-color: colors.$white;
          }
        }
        .MuiTypography-root {
          color: #232323;
          font-size: 16px;
          font-family: "Alegreya Sans";
          // line-height: 21px;
        }

        a {
          text-transform: none;
          text-decoration: underline;
          color: colors.$black;
        }
      }
    }

    .set-password-button {
      // width: 50%;
      color: colors.$white;
      font-size: 23px;
      font-family: "Alegreya Sans";
      font-weight: 600;
      line-height: 30px;
      text-transform: none;
      outline: none;
      text-wrap: nowrap;
    }
  }
}

@media (width > 500px) {
  .set-password-container .set-password-form-container {
    width: 70%;
  }
}

@media (width > 700px) {
  .set-password-container .set-password-form-container {
    width: 50%;
  }
}

@media (width > 900px) {
  .set-password-container .set-password-form-container {
    width: 40%;
  }
}
