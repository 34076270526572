@use "../../styles/colors";

.menu-popover-container {
  width: 300px;
  background-color: colors.$black;

  .menu-popover-title {
    color: colors.$white;
    text-align: center;
    background-color: colors.$black;
  }
}

.menu-popover-item {
  display: grid;
  color: colors.$white;
  grid-template-columns: 100px 3fr 40px;
  align-items: center;
  justify-items: end;
  background-color: colors.$black;
  .menu-popover-item-title {
    justify-self: left;
    text-transform: uppercase;

    span {
      font-size: 0.8rem;
    }
  }
  .menu-popover-item-select {
    span {
      font-size: 0.8rem;
      text-align: right;
    }
  }
}

.menu-popover-item-list-item {
  color: colors.$white;
  text-align: left;
  background-color: colors.$black;

  &.selected {
    background-color: colors.$dark-gray;
  }
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: colors.$white;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: colors.$white;
}

@media (width > 769px) {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    color: #000;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: none;
  }
}
