@import "./../../styles/colors";

// @media (max-width: 768px) {
.profileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profileHeader {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
    background-color: $black;
    border-radius: 0 0 10% 10%;

    color: $white;

    .profile-avatar {
      background-color: $white;
      border: 10px solid $yellow;
      width: clamp(100px, 35vw, 147px);
      height: clamp(100px, 35vw, 147px);
    }

    .title {
      font-weight: 700;
      font-size: clamp(20px, 4vw, 30px);
      text-align: center;
      margin: 0;
    }
    .subtitle {
      font-weight: 400;
      font-size: clamp(12px, 4vw, 20px);
      text-align: center;
      margin: 0;
    }
  }

  .profile-link-container {
    height: 60%;
    width: 100%;
    max-width: 1200px;
    padding: 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .profile-link-icon {
      color: $yellow;
      margin-right: 2rem;
    }
    .profile-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      line-height: 0;

      p {
        padding: 10px;
        font-size: 20px;
        color: $black;
      }
    }
    .profile-logout-button {
      margin: 20px auto;
      height: 48px;
      width: 235px;
      font-size: 25px;
      font-weight: 700;
      align-self: flex-end;
    }
  }
}
// }
